import Logo from "@/components/LogoNew";
import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

type ChefProps = {
  id: number;
  title: string;
  slug: string;
  uri: string;
  image: {
    id: number;
    title: string;
    url: string;
    width: string;
    height: string;
  };
};

const ChefToken = ({
  chef = undefined,
  isMob = false,
  size = "sm",
  theme = "dark",
}: {
  chef?: ChefProps;
  isMob?: boolean;
  size?: "sm" | "md";
  theme?: "dark" | "light";
}) => {
  // The chef might be "mob"
  isMob = chef?.slug === "mob" || isMob;

  const image = chef?.image?.[0];

  const className = clsx("group flex items-center", {});

  const _Wrapper = ({ children }) => {
    if (isMob) {
      return <div className={clsx(className)}>{children}</div>;
    }

    return (
      <Link href={`/chefs/${chef.slug}`} prefetch={false}>
        <a className={className}>{children}</a>
      </Link>
    );
  };

  return (
    <_Wrapper>
      <div
        className={clsx(
          "relative isolate flex aspect-square items-center justify-center overflow-hidden rounded-full",
          {
            "h-6 w-6": size === "sm",
            "w-component h-component": size === "md",
            "bg-zinc-700 text-zinc-50": theme === "dark",
            "bg-zinc-800 text-zinc-200": theme === "light",
          }
        )}
      >
        {!!image && !!image.url && (
          <Image
            src={image.url}
            layout="fill"
            objectFit="cover"
            alt={chef.title}
            sizes="24px"
            loader={cloudflareLoader}
          />
        )}
        {isMob && (
          <div
            className={clsx("flex items-center justify-center", {
              "h-4 w-4": size === "sm",
              "h-7 w-7": size === "md",
            })}
          >
            <Logo className={clsx("block w-full")} />
          </div>
        )}
      </div>

      <div
        className={clsx(
          "font-body opacity-75 transition ease-out group-hover:opacity-100",
          {
            "px-2 text-sm": size === "sm",
            "px-2.5 text-sm": size === "md",
          }
        )}
      >
        {isMob ? "Mob" : chef.title}
      </div>
    </_Wrapper>
  );
};

export default ChefToken;
